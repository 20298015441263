// App service for determining the bundle and tenant from the hostname and storing them in a context.
// Exports a provider and a custom hook for accessing the bundle and tenant IDs.

import { createContext, useContext, useState } from "react";
import axios from "axios";
import { BundleIds, TenantIds } from "app/shared/constants";

const AppContext = createContext();

const getBundleId = (hostname) => {
  // Get the app subdomain
  const subdomain = hostname.split(".")[0];

  // Determine the subdomain suffix
  const subdomainSuffix = `-${window.DEPLOY_ENV}`;

  // Determine existence of suffix in subdomain
  const suffixIndex = subdomain.indexOf(subdomainSuffix);

  // Extract the bundle id
  return suffixIndex > 0 ? subdomain.slice(0, suffixIndex) : subdomain;
};

const getTenantId = (bundleId) => {
  switch (bundleId) {
    case BundleIds.MEDONBOARD_ADMIN:
    case BundleIds.MEDONBOARD_PORTAL:
      return TenantIds.MEDONBOARD;
    case BundleIds.MEDACT_ADMIN:
      return TenantIds.MEDACT;
    case BundleIds.MEDLEAVE_ADMIN:
      return TenantIds.MEDLEAVE;
    default:
      return TenantIds.UNKNOWN;
  }
};

export const AppProvider = ({ children }) => {
  const [state] = useState(() => {
    const hostname = window.location.hostname;
    const bundleId =
      hostname === "localhost"
        ? process.env.REACT_APP_LOCALHOST_BUNDLE_ID
        : getBundleId(hostname);
    const tenantId = getTenantId(bundleId);

    return { bundleId, tenantId };
  });

  // Set the tenant ID in the axios header
  axios.defaults.headers.common["x-tenant-id"] = state.tenantId;

  return (
    <AppContext.Provider
      value={{ bundleId: state.bundleId, tenantId: state.tenantId }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom hook
export const useApp = () => {
  return useContext(AppContext);
};
