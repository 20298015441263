import { AdminApp } from "app/AdminApp";
import { PortalApp } from "app/PortalApp";
import { useApp } from "app/appService";
import { BundleIds } from "app/shared/constants";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

export const App = () => {
  const { bundleId } = useApp();
  switch (bundleId) {
    case BundleIds.MEDONBOARD_ADMIN:
    case BundleIds.MEDACT_ADMIN:
    case BundleIds.MEDLEAVE_ADMIN:
      return <AdminApp />;
    case BundleIds.MEDONBOARD_PORTAL:
      return <PortalApp />;
    default:
      return null;
  }
};
