// Actions service handling integration with the actions API
// and transformation of action data. Exports a custom hook.

import { useApp } from "app/appService";
import { BundleIds } from "app/shared/constants";
import axios from "axios";
import { useAlerts, useUsers } from "common";
import { format, isValid } from "date-fns";
import isEmpty from "lodash/isEmpty";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { getStoreValue } from "app/shared/utils";
import { useLocation } from "react-router-dom";
import isJSON from "is-json";

const ActionsContext = createContext();

const getInitialFilters = () => {
  return {
    action_id: getStoreValue("filters")?.["action_id"] || null, // MedOnboard, MedACT
    candidate_last_name:
      getStoreValue("filters")?.["candidate_last_name"] || null, // MedOnboard, MedACT
    candidate_first_name:
      getStoreValue("filters")?.["candidate_first_name"] || null, // MedOnboard, MedACT
    candidate_primary_division_org:
      getStoreValue("filters")?.["candidate_primary_division_org"] || null, // MedOnboard, MedACT
    candidate_primary_department_org:
      getStoreValue("filters")?.["candidate_primary_department_org"] || null, // MedOnboard, MedACT
    candidate_start_date:
      getStoreValue("filters")?.["candidate_start_date"] || null, // MedOnboard, MedACT
    candidate_enddate: getStoreValue("filters")?.["candidate_enddate"] || null, // MedOnboard, MedACT
    candidate_status: getStoreValue("filters")?.["candidate_status"] || null, // MedOnboard
    candidate_academic_line_choice:
      getStoreValue("filters")?.["candidate_academic_line_choice"] || null, // MedACT
    candidate_academic_rank:
      getStoreValue("filters")?.["candidate_academic_rank"] || null, // MedACT
    term_sheet_status: getStoreValue("filters")?.["term_sheet_status"] || null, // MedACT
    faculty_last_name: getStoreValue("filters")?.["faculty_last_name"] || null, // MedLeave
    faculty_first_name:
      getStoreValue("filters")?.["faculty_first_name"] || null, // MedLeave
    faculty_primary_department_org:
      getStoreValue("filters")?.["faculty_primary_department_org"] || null, // MedLeave
    faculty_primary_division_org:
      getStoreValue("filters")?.["faculty_primary_division_org"] || null, // MedLeave
    leave_type: getStoreValue("filters")?.["leave_type"] || null, // MedLeave
    status: getStoreValue("filters")?.["status"] || null, // MedLeave
    request_status: getStoreValue("filters")?.["request_status"] || null, // MedLeave
    leave_start_date_begin:
      getStoreValue("filters")?.["leave_start_date_begin"] || null, // MedLeave
    leave_start_date_end:
      getStoreValue("filters")?.["leave_start_date_end"] || null, // MedLeave
    leave_end_date_begin:
      getStoreValue("filters")?.["leave_end_date_begin"] || null, // MedLeave
    leave_end_date_end:
      getStoreValue("filters")?.["leave_end_date_end"] || null, // MedLeave
    action_query_options:
      getStoreValue("filters")?.["action_query_options"] || null, // MedLeave
    rpm_contact_name: getStoreValue("filters")?.["rpm_contact_name"] || null, // MedLeave
  };
};

const initialActionState = {
  pageSize: 25,
  pageNo: 0,
  sortModel: {
    medonboard: [
      {
        field: "candidateName",
        sort: "asc",
      },
    ],
    medact: [
      {
        field: "candidateName",
        sort: "asc",
      },
    ],
    medleave: [
      {
        field: "facultyName",
        sort: "asc",
      },
    ],
  },
};

export const ActionsProvider = ({ children }) => {
  const [filters, setFilters] = useState(() => getInitialFilters());
  const [actionState, setActionState] = useState(initialActionState);
  return (
    <ActionsContext.Provider
      value={{ filters, setFilters, actionState, setActionState }}
    >
      {children}
    </ActionsContext.Provider>
  );
};

const actionsReducer = (state, payload) => {
  switch (payload.action) {
    case "SET_PAGE":
      return { ...state, ...{ pageNo: payload.value } };
    case "SET_PAGE_SIZE":
      return { ...state, ...{ pageSize: payload.value } };
    case "SET_SORT_MODEL":
      return { ...state, ...{ sortModel: payload.value } };
    case "SET_FILTERS":
      return { ...state, ...{ filters: payload.value } };
    case "SET_ACTION_ITEMS":
      return {
        ...state,
        ...{
          actionItems: payload.value.actionItems,
          totalRows: payload.value.totalRows,
          gridHeight: payload.value.gridHeight,
        },
      };
    case "SET_FORM_LINKS":
      return { ...state, ...{ links: payload.value } };
    default:
      return state;
  }
};

export const saveAction = async ({
  id,
  values,
  setSubmitting,
  formTitle,
  resetForm,
  setServiceUrl,
  clearAlert,
  setAlert,
  t,
  navigate,
  formFields,
  tenantId,
  formik,
  setConfirmationDialog,
  request,
  redirectUrl,
  serviceUrl,
  setFormUrl,
}) => {
  try {
    setSubmitting(true);

    clearAlert();

    // Destructuring requestType & Url
    const { requestType, url } = request || {
      requestType: "",
      url: "",
    };

    // Transforming data
    const data = getTransformedData(formFields, values);

    // Appending special variable after data transformation
    data["_request_type"] = values["_request_type"];

    // Making server call to create/update form data
    const response =
      requestType &&
      url &&
      (await axios({
        method: requestType,
        url: url,
        data,
      }));

    // to onboarding request confirmation page, in case of duplicate candidate first name and last name
    if (
      response &&
      ("onboarding_request_confirmation_form" in response.data._links ||
        "leave_request_confirmation_form" in response.data._links)
    ) {
      setServiceUrl({
        apiUrl:
          response.data._links[Object.keys(response.data._links)?.[0]]?.href,
      });
      setFormUrl(
        response.data._links[Object.keys(response.data._links)?.[0]]?.href
      );
    }

    // Checking for redirectUrl to find out whether need to navigate to actions page OR
    else if (serviceUrl) {
      setServiceUrl({ apiUrl: serviceUrl });
      setFormUrl(serviceUrl);
    } else navigate(redirectUrl ? redirectUrl : "/");

    if (response && response.data) {
      const name = {
        medonboard: `${response.data.values.candidate_last_name}, ${
          response.data.values.candidate_first_name
        } ${response.data.values.candidate_middle_name || ""}`,
        medact: `${response.data.values.candidate_last_name}, ${
          response.data.values.candidate_first_name
        } ${response.data.values.candidate_middle_name || ""}`,
        medleave: `${response.data.values.faculty_last_name}, ${
          response.data.values.faculty_first_name
        } ${response.data.values.faculty_middle_name || ""}`,
      };

      const successMessage = getActionMessage(
        t,
        id,
        name,
        response,
        formTitle,
        tenantId
      );

      if (response.data.status !== "DRAFT")
        setAlert("success", successMessage, true);
    }
  } catch (error) {
    console.log(error.stack);

    if (setConfirmationDialog) {
      setConfirmationDialog((confirmationDialog) => {
        return { ...confirmationDialog, show: false };
      });
    }
    setAlert("error", error.message);
  } finally {
    formik.setErrors({});
    setSubmitting(false);
    resetForm({ values });
  }
};

export const useActions = () => {
  const { tenantId } = useApp();
  const [isLoading, setIsLoading] = useState(false);
  const { clearAlert, setAlert } = useAlerts();
  const { bundleId } = useApp();

  const pageSizeContext = useContext(ActionsContext).actionState.pageSize;
  const pageNoContext = useContext(ActionsContext).actionState.pageNo;

  const location = useLocation();
  const { pathname } = location;

  const {
    currentUser: { _links, permissions },
  } = useUsers();

  /** Actions useReducer */
  const [actions, dispatch] = useReducer(actionsReducer, {
    pageSize: getStoreValue("page")?.pageSize || pageSizeContext,
    pageNo: getStoreValue("page")?.pageNo || pageNoContext,
    startForm: "",
    sortModel:
      getStoreValue("sort")?.sortModel || getSortModel(useContext, tenantId),
    totalRows: 0,
    gridHeight: 150,
    filters: useContext(ActionsContext).filters,
    actionItems: [],
  });

  const loadAllActions = useCallback(
    async (pageSize, pageNo, sortModelObj, actionsFilter) => {
      try {
        clearAlert();
        setIsLoading(true);

        // To get mapped column for input column with UI column.
        let sortBy = getMappedColumn(sortModelObj[0].field);
        let sortOrder = sortModelObj[0].sort;

        // Initializing filters object
        let filters;

        // To get mapped filters based on actionsFilter.
        if (actionsFilter) {
          filters = getMappedFilters(actionsFilter);
        }

        const fields = {
          medonboard: [
            "candidate_last_name",
            "candidate_first_name",
            "candidate_middle_name",
            "candidate_primary_department_name",
            "candidate_primary_division_name",
            "candidate_primary_department_org",
            "candidate_primary_division_org",
            "candidate_start_date",
            "status_with_days",
          ],
          medact: [
            "candidate_last_name",
            "candidate_first_name",
            "candidate_middle_name",
            "candidate_primary_department_name",
            "candidate_primary_division_name",
            "candidate_primary_department_org",
            "candidate_primary_division_org",
            "candidate_academic_line_choice",
            "candidate_start_date",
            "status_with_days",
          ],
          medleave: [
            "faculty_last_name",
            "faculty_first_name",
            "faculty_middle_name",
            "faculty_primary_department_name",
            "faculty_primary_division_name",
            "leave_type",
            "leave_start_date",
            "leave_end_date",
            "status",
            "request_status_with_days",
          ],
        };

        const params = {
          values: fields[tenantId].join(","),
          ps: pageSize,
          p: pageNo + 1,
          s: `${sortBy}:${sortOrder}`,
          params: !isEmpty(filters) ? JSON.stringify(filters) : undefined,
        };

        const response = await axios({
          url: _links?.actions?.href,
          method: "GET",
          params,
        });

        if (response.data.totalCount) {
          const actionItems = response.data.values.map((action) =>
            Object.assign(
              {},
              {
                id: action.id,
                candidateNameSequence: formatCandidateName(
                  action.values,
                  "sequence",
                  ""
                ),
                candidateName: formatCandidateName(action.values), // MedOnboard, MedACT
                hiringDepartment: getOrgString("department", action.values), // MedOnboard, MedACT
                hiringDivision: getOrgString("division", action.values), // MedOnboard, MedACT
                startDate:
                  action.values.candidate_start_date &&
                  getFormattedDate(action.values.candidate_start_date), // MedOnboard, MedACT
                status: action.status, // MedOnboard
                termSheetStatus: action.values?.status_with_days, // MedACT
                candidateStatus: action.values.status_with_days, // MedOnboard
                candidateLine: `${action.values.candidate_academic_line_choice}`, // MedACT
                facultyName: `${action.values.faculty_last_name}, ${
                  action.values.faculty_first_name
                } ${action.values.faculty_middle_name || ""}`, // MedLeave
                department: `${
                  action.values.faculty_primary_department_name || ""
                } ${
                  action.values.faculty_primary_department_org
                    ? action.values.faculty_primary_department_org
                    : ""
                }`, // MedLeave
                division: `${
                  action.values.faculty_primary_division_name || ""
                } ${
                  action.values.faculty_primary_division_org
                    ? action.values.faculty_primary_division_org
                    : ""
                }`, // MedLeave
                leaveType: action.values.leave_type, // MedLeave
                leaveStartDate:
                  action.values.leave_start_date &&
                  getFormattedDate(action.values.leave_start_date), // MedLeave
                leaveEndDate:
                  action.values.leave_end_date &&
                  getFormattedDate(action.values.leave_end_date), // MedLeave
                requestStatus: action.values.request_status_with_days, // MedLeave
                links: "",
                hateos: action._links,
              }
            )
          );
          dispatch({
            action: "SET_ACTION_ITEMS",
            value: {
              actionItems,
              totalRows: response.data.totalCount,
              gridHeight: (response.data.count + 3) * 52,
            },
          });
        } else {
          dispatch({
            action: "SET_ACTION_ITEMS",
            value: {
              actionItems: [],
              totalRows: 0,
              gridHeight: 3 * 52,
            },
          });
        }

        dispatch({
          action: "SET_FORM_LINKS",
          value: {
            apiUrl: response.data._links || "",
          },
        });
      } catch (error) {
        console.log(error);
        setAlert("error", error.message);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearAlert, setAlert, tenantId]
  );

  useEffect(() => {
    if (
      bundleId !== BundleIds.MEDONBOARD_PORTAL &&
      ["/", "/actions"].includes(pathname)
    ) {
      loadAllActions(
        actions.pageSize,
        actions.pageNo,
        actions.sortModel,
        actions.filters
      );
    }
  }, [
    loadAllActions,
    actions.pageSize,
    actions.pageNo,
    actions.sortModel,
    actions.filters,
    bundleId,
    pathname,
  ]);

  const loadFilters = useCallback(
    async (setFilterOptions, setInitialQueryOptionFilter, dispatch) => {
      try {
        clearAlert();
        const params = {
          medonboard: [
            "_action_id",
            "candidate_last_name",
            "candidate_first_name",
            "candidate_primary_division_org",
            "candidate_primary_department_org",
          ],
          medact: [
            "_action_id",
            "candidate_last_name",
            "candidate_first_name",
            "candidate_primary_division_org",
            "candidate_primary_department_org",
            "candidate_academic_line_choice",
            "candidate_academic_rank",
            "term_sheet_status",
          ],
          medleave: [
            "_action_id",
            "faculty_last_name",
            "faculty_first_name",
            "faculty_primary_division_org",
            "faculty_primary_department_org",
            "leave_type_choice",
            "request_status",
            "action_query_options",
            "rpm_contact_name",
          ],
        };

        const response = await axios({
          url: _links?.filters?.href,
          method: "GET",
          params: {
            value: params[tenantId].join(","),
          },
        });

        setFilterOptions(response.data);

        if (response.data) {
          const initialFilter = (id, payload) => {
            switch (id) {
              case "medleave":
                // MedLeave helper function to se action query options
                return setInitialQueryOptionFilter(
                  payload,
                  dispatch,
                  permissions
                );
              case "medact":
                break;
              case "medonboard":
                break;
              default:
                break;
            }
          };
          // eslint-disable-next-line no-eval
          eval(initialFilter(tenantId, response.data));
        }
      } catch (error) {
        setAlert("error", error.message);
      }
    },
    [clearAlert, setAlert, tenantId, _links?.filters?.href, permissions]
  );

  const loadFieldHistory = useCallback(
    async ({ links, setIsHistoryLoading, setShow, setHistoryItems }) => {
      try {
        clearAlert();

        if (Boolean(links)) {
          setIsHistoryLoading(true);
          const response = await axios({
            url: links,
            method: "GET",
          });
          setHistoryItems(response.data.values || []);
        }
      } catch (error) {
        setShow(false);
        setAlert("error", error.message);
      } finally {
        setIsHistoryLoading(false);
      }
    },
    [clearAlert, setAlert]
  );

  const loadActionHistory = useCallback(
    async ({ actionId, setIsLoading, params, setParams, setItems }) => {
      try {
        clearAlert();
        setIsLoading(true);

        const URL = params.link;
        const response = await axios({
          url: URL,
          method: "GET",
        });
        setItems(response.data.values || []);
      } catch (error) {
        setParams({ ...params, show: false });
        setAlert("error", error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [clearAlert, setAlert]
  );

  // Fn to return useContext for Actions

  return {
    loadAllActions, // GET ALL actions
    saveAction, // POST / PUT Actions
    loadFilters, // GET ALL filters
    loadFieldHistory, // GET History
    filters: useContext(ActionsContext).filters, // Context for Actions
    setFilters: useContext(ActionsContext).setFilters,
    actionState: useContext(ActionsContext).actionState, // Context for Actions
    setActionState: useContext(ActionsContext).setActionState,
    isLoading,
    actions,
    dispatch,
    loadActionHistory, // GET ALL Action History
  };
};

const getActionMessage = (t, id, name, response, formTitle, tenantId) => {
  if (id) {
    if (response.data.status === "INITIATED") {
      return t(`dashboard.action.edit.notification.success.${tenantId}`, {
        facultyName: name[`${tenantId}`],
      });
    } else {
      return t(`forms.notification.success.${tenantId}`, {
        formTitle,
        facultyName: name[`${tenantId}`],
      });
    }
  } else {
    return t(`dashboard.action.create.notification.success.${tenantId}`, {
      facultyName: name[`${tenantId}`],
    });
  }
};

const getFormattedDate = (date) => {
  const newDate = new Date(date);
  return format(
    new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000),
    "yyyy/MM/dd"
  );
};

const getMappedColumn = (inputColumn) => {
  if (inputColumn === "candidateName") {
    // MedOnboard, MedACT
    return "candidate_last_name,candidate_first_name";
  } else if (inputColumn === "hiringDivision") {
    // MedOnboard, MedACT
    return "candidate_primary_division_org";
  } else if (inputColumn === "hiringDepartment") {
    // MedOnboard, MedACT
    return "candidate_primary_department_org";
  } else if (inputColumn === "startDate") {
    // MedOnboard, MedACT
    return "candidate_start_date";
  } else if (inputColumn === "id") {
    // MedOnboard, MedACT
    return "action_id";
  } else if (inputColumn === "candidateStatus") {
    // MedOnboard
    return "status_with_days";
  } else if (inputColumn === "status") {
    // MedOnboard, MedLeave
    return "status";
  } else if (inputColumn === "candidateLine") {
    // MedACT
    return "candidate_academic_line_choice";
  } else if (inputColumn === "facultyName") {
    // MedLeave
    return "faculty_last_name,faculty_first_name";
  } else if (inputColumn === "department") {
    // MedLeave
    return "faculty_primary_department_org";
  } else if (inputColumn === "division") {
    // MedLeave
    return "faculty_primary_division_org";
  } else if (inputColumn === "leaveType") {
    // MedLeave
    return "leave_type";
  } else if (inputColumn === "leaveStartDate") {
    // MedLeave
    return "leave_start_date";
  } else if (inputColumn === "leaveEndDate") {
    // MedLeave
    return "leave_end_date";
  } else if (inputColumn === "requestStatus") {
    // MedLeave
    return "request_status_with_days";
  } else if (inputColumn === "termSheetStatus") {
    // MedACT
    return "status_with_days";
  }
};

const getMappedFilters = (actionsFilter) => {
  let filters = {};

  if (actionsFilter.action_id) {
    // MedACT
    filters["action_id"] = actionsFilter.action_id;
  }
  if (actionsFilter.candidate_last_name) {
    // MedOnboard, MedACT
    filters["candidate_last_name"] = actionsFilter.candidate_last_name;
  }
  if (actionsFilter.candidate_middle_name) {
    // MedOnboard, MedACT
    filters["candidate_middle_name"] = actionsFilter.candidate_middle_name;
  }
  if (actionsFilter.candidate_first_name) {
    // MedOnboard, MedACT
    filters["candidate_first_name"] = actionsFilter.candidate_first_name;
  }
  if (actionsFilter.candidate_primary_department_org) {
    // MedOnboard, MedACT
    filters["candidate_primary_department_org"] =
      actionsFilter.candidate_primary_department_org.orgCode;
  }
  if (actionsFilter.candidate_primary_division_org) {
    // MedOnboard, MedACT
    filters["candidate_primary_division_org"] =
      actionsFilter.candidate_primary_division_org.orgCode;
  }
  if (actionsFilter.candidate_start_date) {
    // MedOnboard, MedACT
    filters["candidate_start_date:gt"] =
      actionsFilter.candidate_start_date &&
      format(new Date(actionsFilter.candidate_start_date), "yyyy-MM-dd");
  }
  if (actionsFilter.candidate_enddate) {
    // MedOnboard, MedACT
    filters["candidate_start_date:lt"] =
      actionsFilter.candidate_enddate &&
      format(new Date(actionsFilter.candidate_enddate), "yyyy-MM-dd");
  }
  if (actionsFilter.candidate_status) {
    // MedOnboard
    filters["status"] = actionsFilter.candidate_status.code;
  }
  if (actionsFilter.candidate_academic_line_choice) {
    // MedACT
    filters["candidate_academic_line_choice"] =
      actionsFilter.candidate_academic_line_choice.text;
  }
  if (actionsFilter.candidate_academic_rank) {
    // MedACT
    filters["candidate_academic_rank"] =
      actionsFilter.candidate_academic_rank.text;
  }
  if (actionsFilter.term_sheet_status) {
    // MedACT
    filters["term_sheet_status"] = actionsFilter.term_sheet_status;
  }
  if (actionsFilter.faculty_last_name) {
    // MedLeave
    filters["faculty_last_name"] = actionsFilter.faculty_last_name;
  }
  if (actionsFilter.faculty_first_name) {
    // MedLeave
    filters["faculty_first_name"] = actionsFilter.faculty_first_name;
  }
  if (actionsFilter.faculty_primary_department_org) {
    // MedLeave
    filters["faculty_primary_department_org"] =
      actionsFilter.faculty_primary_department_org.orgCode;
  }
  if (actionsFilter.faculty_primary_division_org) {
    // MedLeave
    filters["faculty_primary_division_org"] =
      actionsFilter.faculty_primary_division_org.orgCode;
  }
  if (actionsFilter.leave_type) {
    // MedLeave
    filters["leave_type"] = actionsFilter.leave_type.id;
  }
  if (actionsFilter.status) {
    // MedLeave
    const filteredValues = actionsFilter.status.map((status) => status.code);
    filters["status"] =
      filteredValues.length > 0 ? filteredValues.toString() : filteredValues;
  }
  if (actionsFilter.request_status) {
    // MedLeave
    filters["request_status"] = actionsFilter.request_status;
  }
  if (actionsFilter.leave_start_date_begin) {
    // MedLeave
    filters["leave_start_date:gt"] =
      actionsFilter.leave_start_date_begin &&
      format(new Date(actionsFilter.leave_start_date_begin), "yyyy-MM-dd");
  }
  if (actionsFilter.leave_start_date_end) {
    // MedLeave
    filters["leave_start_date:lt"] =
      actionsFilter.leave_start_date_end &&
      format(new Date(actionsFilter.leave_start_date_end), "yyyy-MM-dd");
  }
  if (actionsFilter.leave_end_date_begin) {
    // MedLeave
    filters["leave_end_date:gt"] =
      actionsFilter.leave_end_date_begin &&
      format(new Date(actionsFilter.leave_end_date_begin), "yyyy-MM-dd");
  }
  if (actionsFilter.leave_end_date_end) {
    // MedLeave
    filters["leave_end_date:lt"] =
      actionsFilter.leave_end_date_end &&
      format(new Date(actionsFilter.leave_end_date_end), "yyyy-MM-dd");
  }
  if (actionsFilter.action_query_options) {
    // MedLeave
    filters["action_query_options"] = actionsFilter.action_query_options.id;
  }
  if (actionsFilter.rpm_contact_name) {
    // MedLeave
    filters["rpm_contact_name"] = actionsFilter.rpm_contact_name.id;
  }
  return filters;
};

const getTransformedData = (formFields, values) => {
  const data = {};
  try {
    formFields.forEach((item) => {
      // If values are not available

      // CONDITION for blank fields - NON REQUIRED FIELDS

      // Here, we send blank string ("") for the fields TEXT, NUMBER, MONEY, PERCENT
      // and send stringified empty object for the fields RADIO, DROPDOWN
      // or send stringified empty array for the fields CHECKBOX
      if (!values[item.field_id] && values[item.field_id] !== 0) {
        if (
          ["text", "number", "money", "percent", "date"].includes(
            item.field_type
          )
        ) {
          values[item.field_id] = "";
        } else if (["radio", "dropdown"].includes(item.field_type)) {
          values[item.field_id] = JSON.stringify({});
        } else if (["checkbox"].includes(item.field_type)) {
          values[item.field_id] = JSON.stringify([]);
        }
      }

      const { decimalPlaces } = isJSON(item.field_attributes)
        ? JSON.parse(item.field_attributes)
        : {};

      // DO NOT SEND fields whose field_editable set to false.
      if (String(item.field_editable).trim() === "false") return false;

      // Applying formatting on the fields to be send.
      if (
        ["number", "percent"].includes(item.field_type.trim()) &&
        values[item.field_id]
      ) {
        data[item.field_id] = parseFloat(
          Number(values[item.field_id]).toFixed(decimalPlaces)
        );
      } else if (
        ["date"].includes(item.field_type.trim()) &&
        !isValid(new Date(values[item.field_id]))
      ) {
        data[item.field_id] = "";
      } else if (
        ["money"].includes(item.field_type.trim()) &&
        values[item.field_id]
      ) {
        data[item.field_id] =
          Number(values[item.field_id]) % 1 !== 0
            ? Number(values[item.field_id]).toFixed(decimalPlaces)
            : Number(values[item.field_id]);
      } else if (!item.field_type && item.field_attributes) {
        const fieldType = isJSON(item.field_attributes)
          ? JSON.parse(item.field_attributes)["field_type"]
          : "";
        if (["radio", "dropdown", "checkbox"].includes(fieldType)) {
          const id = values[item.field_id]?.id ?? values[item.field_id];
          const text = values[item.field_id]?.text ?? values[item.field_id];
          data[item.field_id] = id ? JSON.stringify({ id, text }) : "";
        }
      } else {
        data[item.field_id] = values[item.field_id];
      }

      if (["radio", "dropdown", "checkbox"].includes(item.field_type)) {
        if (values[item.field_id] !== "{}" && values[item.field_id] !== "[]") {
          if (!Array.isArray(values[item.field_id])) {
            const id = values[item.field_id]?.id ?? values[item.field_id];
            const text = values[item.field_id]?.text ?? values[item.field_id];
            data[item.field_id] = id ? JSON.stringify({ id, text }) : "";
          } else {
            const valueArray = [];
            values[item.field_id].forEach((i) => {
              valueArray.push({ id: i?.id ?? i, text: i?.text ?? i });
            });
            data[item.field_id] = JSON.stringify(valueArray);
          }
        }
      }
    });
  } catch (error) {
    console.log(error.stack);
  }

  return data;
};

const getSortModel = (context, tenantId) => {
  const sortModel = context(ActionsContext).actionState.sortModel;
  return sortModel?.[tenantId] ?? sortModel;
};

const formatCandidateName = (values, formatType, separator = ",") => {
  const firstName = values.candidate_first_name || "";
  const middleName = values.candidate_middle_name || "";
  const lastName =
    values.candidate_last_name && (firstName || middleName)
      ? `${values.candidate_last_name}${separator}`
      : values.candidate_last_name || "";

  if (!formatType) return `${lastName} ${firstName} ${middleName}`;
  return `${firstName} ${middleName} ${lastName}`;
};

// To get the org string
const getOrgString = (key, data) => {
  // GET name of the org
  let orgName = data[`candidate_primary_${key}_name`] || "";
  // GET code of the org
  let orgCode = data[`candidate_primary_${key}_org`] || "";

  // Condition to apply round brackets only if orgCode is available
  orgCode = orgCode ? `(${orgCode})` : "";

  //Return prepared string
  return `${orgName} ${orgCode}`;
};
